import React from 'react'
import ChooseSection from '../components/ChooseSection'
import TeamSection from '../components/TeamSection'
import HeroAbout from '../components/HeroAbout'

export default function About() {
  return (
    <div>
      <HeroAbout/>
      <ChooseSection/>
      <TeamSection/>
    </div>
  )
}
