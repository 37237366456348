import React from 'react'
import img1 from '../assets/images/Location engins/engin-1.avif'
import img2 from '../assets/images/Location engins/engin-2.jpg'
import img3 from '../assets/images/Location engins/engin-3.jpg'
import cross from '../assets/images/cross.svg'
import { Link } from 'react-router-dom'

export default function LocationEngin() {
  return (
    <div>
     {/* Start Product Section */}
<div className="product-section">
  <div className="container">
    <div className="row">
      {/* Start Column 1 */}
      <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
        <h2 className="mb-4 section-title">Location des Engins.</h2>
        <p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. </p>
        <p><Link to='/shop'  className="btn">Explorer</Link></p>
      </div> 
      {/* End Column 1 */}
      {/* Start Column 2 */}
      <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
        <a className="product-item" href="cart.html">
          <img src={img1} alt='' className="img-fluid product-thumbnail" />
          <h3 className="product-title">Engins</h3>
          {/* <strong className="product-price">10000FCFA/Jour</strong> */}
          <span className="icon-cross">
            <img src={cross} alt='' className="img-fluid" />
          </span>
        </a>
      </div> 
      {/* End Column 2 */}
      {/* Start Column 3 */}
      <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
        <a className="product-item" href="cart.html">
          <img src={img2}alt='' className="img-fluid product-thumbnail" />
          <h3 className="product-title">Engins</h3>
          {/* <strong className="product-price">15000FCFA/Jour</strong> */}
          <span className="icon-cross">
            <img src={cross} alt='' className="img-fluid" />
          </span>
        </a>
      </div>
      {/* End Column 3 */}
      {/* Start Column 4 */}
      <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
        <a className="product-item" href="cart.html">
          <img src={img3} alt='' className="img-fluid product-thumbnail" />
          <h3 className="product-title">Engins</h3>
          {/* <strong className="product-price">20000FCFA/Jour</strong> */}
          <span className="icon-cross">
            <img src={cross} alt=''className="img-fluid" />
          </span>
        </a>
      </div>
      {/* End Column 4 */}
    </div>
  </div>
</div>
{/* End Product Section */}

    </div>
  )
}
