import React from 'react'
import HeroShop from '../components/HeroShop'
import ShopContent from '../components/ShopContent'

export default function Shop() {
  return (
    <div>
      <HeroShop/>
      <ShopContent/>
    </div>
  )
}
