import React from 'react'
import ProductSection from '../components/ProductSection'
import Hero from '../components/Hero'
import ChooseSection from '../components/ChooseSection'
import HelpSection from '../components/HelpSection'
import PopularProduct from '../components/PopularProduct'
// import TestimonialSlider from '../components/TestimonialSlider'
import BlogSection from '../components/BlogSection'

export default function Home() {
  return (
    <div>
       <Hero/>
     {/* <ProductSection/> */}
     <ChooseSection/>
     <HelpSection/>
     <PopularProduct/>
     {/* <TestimonialSlider/> */}
     <BlogSection/>
    </div>
  )
}
