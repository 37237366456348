import React from 'react'
import img from '../assets/images/blog.jpg'
import { Link } from 'react-router-dom'

export default function HeroBlog() {
  return (
    <div>
     {/* Start Hero Section */}
<div className="hero">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-5">
        <div className="intro-excerpt">
          <h1>Blog</h1>
          <p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
          <p><Link to='/shop'  className="btn btn-secondary me-2">Nos Produits</Link></p>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="hero-img-wrap">
          <img src={img} alt='' className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Hero Section */}

    </div>
  )
}
