import React from 'react'
import truck from '../assets/images/truck.svg'
import bag from '../assets/images/bag.svg'
import support from '../assets/images/support.svg'
import returne from '../assets/images/return.svg'

export default function ChooseService() {
  return (
    <div>
      {/* Start Why Choose Us Section */}
<div className="why-choose-section">
  <div className="container">
    <div className="row my-5">
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={truck} alt="Image" className="imf-fluid" />
          </div>
          <h3>Fast &amp; Free Shipping</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={bag} alt="Image" className="imf-fluid" />
          </div>
          <h3>Easy to Shop</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={support} alt="Image" className="imf-fluid" />
          </div>
          <h3>24/7 Support</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={returne} alt="Image" className="imf-fluid" />
          </div>
          <h3>Hassle Free Returns</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      {/* <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={truck} alt="Image" className="imf-fluid" />
          </div>
          <h3>Fast &amp; Free Shipping</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={bag} alt="Image" className="imf-fluid" />
          </div>
          <h3>Easy to Shop</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={support} alt="Image" className="imf-fluid" />
          </div>
          <h3>24/7 Support</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-3 mb-4">
        <div className="feature">
          <div className="icon">
            <img src={returne} alt="Image" className="imf-fluid" />
          </div>
          <h3>Hassle Free Returns</h3>
          <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
        </div>
      </div> */}
    </div>
  </div>
</div>
{/* End Why Choose Us Section */}

    </div>
  )
}
