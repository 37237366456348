import React from 'react'
import img from '../assets/images/shop.jpg'


export default function HeroShop() {
  return (
    <div>
     {/* Start Hero Section */}
<div className="hero">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-5">
        <div className="intro-excerpt">
          <h1>Nos Produits</h1>
          <p className="mb-4">Passez vos commandes nous vous livrerons...</p>

        </div>
      </div>
      <div className="col-lg-7">
      <div className="hero-img-wrap">
          <img src={img} alt='' className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Hero Section */}


    </div>
  )
}
