import React from 'react'
import HeroBlog from '../components/HeroBlog'
import BlogContent from '../components/BlogContent'

export default function Blog() {
  return (
    <div>
      <HeroBlog/>
      <BlogContent/>
    </div>
  )
}
