import React from 'react'
import img1 from '../assets/images/acceuil.jpg'
import logo from '../assets/images/petit_logo.jpg'
import { Link } from 'react-router-dom'


export default function Hero() {

//   const [isActive, setIsActive] = useState(false)
//   const [isActive1, setIsActive1] = useState(false)
//   const [isActive2, setIsActive2] = useState(false)
//   const [isActive3, setIsActive3] = useState(false)
//   const [isActive4, setIsActive4] = useState(false)
//   const [isActive5, setIsActive5] = useState(false)


//   const handleClick1 = () => { 
//     setIsActive(false)
//     setIsActive1(true)
//     setIsActive2(false)
//     setIsActive3(false)
//     setIsActive4(false)
//     setIsActive5(false)
// }

  return (
    <div>
    {/* Start Hero Section */}
<div className="hero">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-5">
        <div className="intro-excerpt">
          <h1>Société Forestière <span clsas="d-block">Industrielle de la Doumé</span></h1>
          <p className="mb-4">La SFID (Société Forestière et Industrielle de la Doumé) est une société du groupe Rougier. Rougier est installé depuis 1969 au Cameroun à travers sa filiale SFID qui exploite aujourd'hui 550 000 ha de forêts pour une production de l'ordre de 200 000 m³ de grumes/an. La SFID emploie plus de 1 000 personnes.</p>
          <p>
            <Link to='/shop' className="btn btn-secondary me-2">Nos Produits</Link><Link to='/'  className="btn btn-white-outline">Explorer</Link></p>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="hero-img-wrap">
          <img src={img1} alt='' className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Hero Section */}

    </div>
  )
}
