import React from 'react'
import cross from '../assets/images/cross.svg'
import img1 from '../assets/images/Vente bois/bois-1.jpg'
import img2 from '../assets/images/Vente bois/bois-2.jpeg'
import img3 from '../assets/images/Vente bois/bois-3.jpg'
import img4 from '../assets/images/Vente bois/bois-4.jpg'
import img5 from '../assets/images/Vente bois/bois-5.webp'
import img6 from '../assets/images/Vente bois/bois-6.webp'
import img7 from '../assets/images/Location engins/engin-1.avif'
import img8 from '../assets/images/Location engins/engin-2.jpg'
import img9 from '../assets/images/Location engins/engin-3.jpg'
import img10 from '../assets/images/Construction maisons/house-1.jpg'
import img11 from '../assets/images/Construction maisons/house-2.webp'
import img12 from '../assets/images/Construction maisons/house-3.jpg'



import { Link } from 'react-router-dom'

export default function ShopContent() {
  return (
    <div>
      		<div class="untree_co-section product-section before-footer-section">
		    <div class="container">
		      	<div class="row">

		      		{/* Start Column 1 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
  <Link to='' className="product-item" >
    <img src={img3} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Bois Nordic</h3>
    {/* <strong className="product-price">$50.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div> 
{/* End Column 1 */}

						
					{/* Start Column 2 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img1} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Bois Rondic</h3>
    {/* <strong className="product-price">$50.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div> 
{/* End Column 2 */}

				{/* Start Column 3 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img2} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Bois Section</h3>
    {/* <strong className="product-price">$78.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 3 */}


					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img4} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Ergonomic Bois</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}



					{/* Start Column 1 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img5} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Nordic Bois</h3>
    {/* <strong className="product-price">$50.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div> 
{/* End Column 1 */}

						
					{/* Start Column 2 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img6} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Bois Chair</h3>
    {/* <strong className="product-price">$50.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div> 
{/* End Column 2 */}


					{/* Start Column 3 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img2} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Kruzo Aero Bois</h3>
    {/* <strong className="product-price">$78.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 3 */}


					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img4} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Ergonomic Wood</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
    <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}

					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img7} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Engins</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}

					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img8} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Engins</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}

					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img9} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Engins</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}
					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img11} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Maison en bois</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}
					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img12} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Maison en bois</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}
					{/* Start Column 4 */}
<div className="col-12 col-md-4 col-lg-3 mb-5">
<Link to='' className="product-item" >
    <img src={img10} alt='' className="img-fluid product-thumbnail" />
    <h3 className="product-title">Maison en bois</h3>
    {/* <strong className="product-price">$43.00</strong> */}
    <span className="icon-cross">
       <Link to='/commande'>
              <img src={cross} alt='' className="img-fluid" />
            </Link>
    </span>
  </Link>
</div>
{/* End Column 4 */}


		      	</div>
		    </div>
		</div>
    </div>
  )
}
