import React from 'react'
import img1 from '../assets/images/truck.svg'
import img2 from '../assets/images/bag.svg'
import img3 from '../assets/images/support.svg'
import img4 from '../assets/images/return.svg'
import img5 from '../assets/images/why-choose-us-img.jpg'

export default function ChooseSection() {
  return (
    <div>
    {/* Start Why Choose Us Section */}
<div className="why-choose-section">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-6">
        <h2 className="section-title">Pourquoi nous choisir?</h2>
        <p>Notre philosophie, nos valeurs et nos expertises dans le domaine du Bois vous offrent notre compréhension en même temps que notre capacité à imaginer les meilleurs moyens pour développer votre productivité, votre activité en ligne et donc votre chiffre d’affaires.</p>
        <div className="row my-5">
          <div className="col-6 col-md-6">
            <div className="feature">
              <div className="icon">
                <img src={img1} alt='' className="imf-fluid" />
              </div>
              <h3>Expédition rapide et gratuite</h3>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="feature">
              <div className="icon">
                <img src={img2} alt="Image" className="imf-fluid" />
              </div>
              <h3>Facile à acheter</h3>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="feature">
              <div className="icon">
                <img src={img3} alt="Image" className="imf-fluid" />
              </div>
              <h3>Assistance 24/7</h3>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="feature">
              <div className="icon">
                <img src={img4} alt="Image" className="imf-fluid" />
              </div>
              <h3>Retour sans problème</h3>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="img-wrap">
          <img src={img5} alt="Image" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Why Choose Us Section */}

    </div>
  )
}
