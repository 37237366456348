import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Shop from './pages/Shop';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import { useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './components/Layout';
import Checkout from './pages/Checkout';


function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Layout  />}>
            <Route index element={<Home />} />
            <Route path='shop' element={<Shop />} /> 
            <Route path='about' element={<About />} /> 
            <Route path='blog' element={<Blog />} /> 
            <Route path='contact' element={<Contact />} /> 
            <Route path='service' element={<Services />} /> 
            <Route path='commande' element={<Checkout />} /> 

          </Route>
        </Routes>
      </BrowserRouter>
  </>
  );
}

export default App;
