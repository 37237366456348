import React from 'react'
import p1 from '../assets/images/person-1.jpg'
import p2 from '../assets/images/person_2.jpg'
import p3 from '../assets/images/person_3.jpg'
import p4 from '../assets/images/person_4.jpg'
import { Link } from 'react-router-dom'

export default function TeamSection() {
  return (
    <div>
    {/* Start Team Section */}
<div className="untree_co-section">
  <div className="container">
    <div className="row mb-5">
      <div className="col-lg-5 mx-auto text-center">
        <h2 className="section-title">Notre Equipe</h2>
      </div>
    </div>
    <div className="row">
      {/* Start Column 1 */}
      <div className="col-12 col-md-6 col-lg-3 mb-5 mb-md-0">
        <img src={p1} alt='' className="img-fluid mb-5" />
        <h3 clas><Link to=''><span className>Lawson</span> Arnold</Link></h3>
        <span className="d-block position mb-4">CEO, Founder, Atty.</span>
        <p>Separated they live in.
          Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
        <p className="mb-0"><Link to='' className="more dark">Learn More <span className="icon-arrow_forward" /></Link></p>
      </div> 
      {/* End Column 1 */}
      {/* Start Column 2 */}
      <div className="col-12 col-md-6 col-lg-3 mb-5 mb-md-0">
        <img src={p2} alt='' className="img-fluid mb-5" />
        <h3 clas><Link to=''><span className>Jeremy</span> Walker</Link></h3>
        <span className="d-block position mb-4">CEO, Founder, Atty.</span>
        <p>Separated they live in.
          Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
        <p className="mb-0"><Link to='' className="more dark">Learn More <span className="icon-arrow_forward" /></Link></p>
      </div> 
      {/* End Column 2 */}
      {/* Start Column 3 */}
      <div className="col-12 col-md-6 col-lg-3 mb-5 mb-md-0">
        <img src={p3} alt='' className="img-fluid mb-5" />
        <h3 clas><Link to=''><span className>Patrik</span> White</Link></h3>
        <span className="d-block position mb-4">CEO, Founder, Atty.</span>
        <p>Separated they live in.
          Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
        <p className="mb-0"><Link to='' className="more dark">Learn More <span className="icon-arrow_forward" /></Link></p>
      </div> 
      {/* End Column 3 */}
      {/* Start Column 4 */}
      <div className="col-12 col-md-6 col-lg-3 mb-5 mb-md-0">
        <img src={p4} alt='' className="img-fluid mb-5" />
        <h3 clas><Link to=''><span className>Kathryn</span> Ryan</Link></h3>
        <span className="d-block position mb-4">CEO, Founder, Atty.</span>
        <p>Separated they live in.
          Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
        <p className="mb-0"><Link to='' className="more dark">Learn More <span className="icon-arrow_forward" /></Link></p>
      </div> 
      {/* End Column 4 */}
    </div>
  </div>
</div>
{/* End Team Section */}

    </div>
  )
}
