import React from 'react'
import img1 from '../assets/images/Vente bois/bois-3.jpg'
import img2 from '../assets/images/Vente bois/bois-1.jpg'
import img3 from '../assets/images/Construction maisons/house-3.jpg'
import { Link } from 'react-router-dom'

export default function PopularProduct() {
  return (
    <div>
     {/* Start Popular Product */}
<div className="popular-product">
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
        <div className="product-item-sm d-flex">
          <div className="thumbnail">
            <img src={img1} alt="Image" className="img-fluid" />
          </div>
          <div className="pt-3">
            <h3>Bois Nordic</h3>
            <p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio </p>
            <p><Link to='/shop'>Voir Plus</Link></p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
        <div className="product-item-sm d-flex">
          <div className="thumbnail">
            <img src={img2} alt="Image" className="img-fluid" />
          </div>
          <div className="pt-3">
            <h3>Rondin de Bois</h3>
            <p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio </p>
            <p><Link to='/shop'>Voir Plus</Link></p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
        <div className="product-item-sm d-flex">
          <div className="thumbnail">
            <img src={img3} alt="Image" className="img-fluid" />
          </div>
          <div className="pt-3">
            <h3>Maison Rusrique en Bois</h3>
            <p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio </p>
            <p><Link to='/shop'>Voir Plus</Link></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Popular Product */}

    </div>
  )
}
