import React, { useState } from 'react'
import user from '../assets/images/user.svg'
// import cart from '../assets/images/cart.svg'
import { Link } from 'react-router-dom'

export default function Header() {

    const [isActive, setIsActive] = useState(true)
    const [isActive1, setIsActive1] = useState(false)
    const [isActive2, setIsActive2] = useState(false)
    const [isActive3, setIsActive3] = useState(false)
    const [isActive4, setIsActive4] = useState(false)
    const [isActive5, setIsActive5] = useState(false)

    const handleClick = () => { 
        setIsActive(true)
        setIsActive1(false)
        setIsActive2(false)
        setIsActive3(false)
        setIsActive4(false)
        setIsActive5(false)
    }
    const handleClick1 = () => { 
        setIsActive(false)
        setIsActive1(true)
        setIsActive2(false)
        setIsActive3(false)
        setIsActive4(false)
        setIsActive5(false)
    }
    const handleClick2 = () => { 
        setIsActive(false)
        setIsActive1(false)
        setIsActive2(true)
        setIsActive3(false)
        setIsActive4(false)
        setIsActive5(false)
    }
    const handleClick3 = () => { 
        setIsActive(false)
        setIsActive1(false)
        setIsActive2(false)
        setIsActive3(true)
        setIsActive4(false)
        setIsActive5(false)
    }
    const handleClick4 = () => { 
        setIsActive(false)
        setIsActive1(false)
        setIsActive2(false)
        setIsActive3(false)
        setIsActive4(true)
        setIsActive5(false)
    }
    const handleClick5 = () => { 
        setIsActive(false)
        setIsActive1(false)
        setIsActive2(false)
        setIsActive3(false)
        setIsActive4(false)
        setIsActive5(true)
    }

  return (
    <div>
  {/* Start Header/Navigation */}
  <nav className="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark" arial-label="Furni navigation bar">
    <div className="container">
      <Link className="navbar-brand" to='/'>SFID<span>.</span> </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarsFurni">
        <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
          <li className={isActive ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick()}>
          {/* <li className="nav-item"> */}
            <Link to='/' className="nav-link">ACCEUIL</Link>
          </li>
          <li className={isActive1 ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick1()}><Link className="nav-link" to='/shop'>Nos Produits</Link></li>
          <li className={isActive3 ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick3()}><Link className="nav-link" to='/service'>Nos Services</Link></li>
            {/* <li> <button className="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Nos Services
                 </button>
                                        <ul className="dropdown-menu dropdown-menu-dark">
                                                <li><Link to='/shop' className="dropdown-item">Vente de Bois</Link></li>
                                                <li><Link to='/service' className="dropdown-item" >Location Engins</Link></li>
                                                <li><Link to='/service' className="dropdown-item" >Construction des Maisons avec du Bois</Link></li>
                                        </ul>
            </li> */}

          <li className={isActive2 ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick2()}><Link className="nav-link" to='/about'>A PROPOS</Link></li>
          {/* <li className={isActive4 ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick4()}><Link className="nav-link" to='/blog'>BLOG</Link></li> */}
          <li className={isActive5 ? 'nav-item active' : 'nav-item'} onClick={()=>handleClick5()}><Link className="nav-link" to='/contact'>CONTACT</Link></li>
        </ul>
        <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
          <li><Link className="nav-link" to='/'><img src={user} alt=''/></Link></li>
          {/* <li><a className="nav-link" href="cart.html"><img src={cart} alt=''/></a></li> */}
        </ul>
      </div>
    </div>
  </nav>
  {/* End Header/Navigation */}
</div>

  )
}
