/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useContext} from 'react'
import { FaCalendar, FaCreditCard, FaLock, FaPhone, FaUber, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import pay from '../../src/assets/images/pay.png'
// import pay1 from '../../src/assets/images/pay/orange money banner.jpg'


const Checkout = () => {
  // const { getTotalCartProducts, getTotalCartAmount, resetCart } = useContext(ShopContext);
  // const totalAmount = getTotalCartAmount();
  // const totalProducts = getTotalCartProducts();
  
  const handlePay = () => {
    alert(`Merci pour votre achat. Votre demande a bien été reçue et est en cours de traitement...`);
    
  };
  
  return <>
  <section className="checkout p-5">
    <div className="container-xxl">
      <div className="row">

        <div className="col-md-6">
        <h1 className="mb-4 fs-3">Informations </h1>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header p-0" id="headingTwo">
              <button className="btn col-12 btn-light btn-block text-start collapsed p-3 rounded-0 border-bottom-custom" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <div className="d-flex align-items-center justify-content-between">
                  <div className='col-6'>
                    {/* <span>Paypal</span> */}
                  </div>
                  <div className='col-6'>
                    {/* <img src={pay} alt="" className='img-fluid' /> */}
                  </div>
                </div>
              </button>
            </div>
            {/* <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="card-body">
                <input type="text" className="form-control" placeholder="Paypal email" required/>
              </div>
            </div> */}
          </div>
          <div className="card m-auto">
            <div className="card-header p-0">
              <button className="btn col-12 btn-light btn-block text-start p-3 rounded-0" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-6">
                  <span>Informations personnels</span>
                  </div>
                  <div className="icons col-6">
                    {/* <img src={pay1} alt="" className='img-fluid' /> */}
                  </div>
                </div>
              </button>
            </div>
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="card-body payment-card-body">
                <span className="font-weight-normal card-text">Nom</span>
                <div className="input mb-4">
                  <i className="fa fa-credit-card"> <FaUser/></i>
                  <input type="text" className="form-control" placeholder="entrez votre nom"   required/>
                </div>
                <span className="font-weight-normal card-text">Prénom</span>
                <div className="input mb-4">
                  <i className="fa fa-credit-card"> <FaUser/></i>
                  <input type="text" className="form-control" placeholder="entrez votre pénom"   required/>
                </div>
                <span className="font-weight-normal card-text">Numéro de Téléphone</span>
                <div className="input mb-4">
                  <i className="fa fa-credit-card"> <FaPhone/></i>
                  <input type="number" className="form-control" placeholder="+237699675640"  pattern="[0-9]+" required/>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-6">
                    <span className="font-weight-normal card-text">Date Achat</span>
                    <div className="input">
                      <i className="fa fa-calendar"> <FaCalendar/>   </i>
                      <input type="text" className="form-control" placeholder="MM/YY" required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span className="font-weight-normal card-text">CVC/CVV</span>
                    <div className="input mb-4">
                      <i className="fa fa-lock"> <FaLock/></i>
                      <input type="text" className="form-control" placeholder="000" />
                    </div>
                  </div>
                </div>
                <span className="text-muted certificate-text"><i className="fa fa-lock"></i>Le mode de  paiement n'est pas encore disponible pour l'instant. Merci pour votre confiance!!!</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    


      <div className="col-md-6 p-2">
            <h1 className=" mt-3 mb-3 fs-3">Remplissez les détails suivants pour l expedition.</h1>
            <form className="row g-3 mb-3">
              <div className="col-md-6">
                <label htmlFor="inputEmail4" className="form-label">Email</label>
                <input type="email" className="form-control" id="inputEmail4"  placeholder="john@gmail.com" pattern=".+@gmail\.com" required />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputPassword4" className="form-label">Mot de passe</label>
                <input type="password" className="form-control" id="inputPassword4" required />
              </div>
              <div className="col-12">
                <label htmlFor="inputNomProduit" className="form-label">Nom du produit</label>
                <input type="text" className="form-control" id="inputAddress" placeholder="Bois Rondic..." />
              </div>
              <div className="col-12">
                <label htmlFor="inputQuantitée" className="form-label">Quantitée(s)</label>
                <input type="numberr" className="form-control" id="inputAddress"  pattern="[0-9]+" placeholder="2kilograms..." required />
              </div>
              <div className="col-12">
                <label htmlFor="inputAddress" className="form-label">Adresse</label>
                <input type="text" className="form-control" id="inputAddress" placeholder="Bertoua enface de la gendamerie..." />
              </div>
              <div className="col-12">
                <label htmlFor="inputAddress2" className="form-label">Adresse 2</label>
                <input type="text" className="form-control" id="inputAddress2" placeholder="Maison,Appartement ou studio" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputCity" className="form-label">Ville</label>
                <input type="text" className="form-control" id="inputCity" placeholder="Bertoua..." />
              </div>

              <div className="col-md-2">
                <label htmlFor="inputZip" className="form-label">Code postale</label>
                <input type="text" className="form-control" id="inputZip" placeholder="00000"/>
              </div>
              <div className="col-md-6 mt-4 ">
               <p> <button id="button-linker" type="submit" className="btn btn-secondary me-2"
                onClick={handlePay}
                >Passer commande</button></p>
              </div>
            </form>
          </div>
        </div>


        </div>
        
        
  </section>
  </>;
}

export default Checkout