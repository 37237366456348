import React from 'react'
import img1 from '../assets/images/post-1.jpg'
import img2 from '../assets/images/post-2.jpg'
import img3 from '../assets/images/post-3.jpg'
import { Link } from 'react-router-dom'

export default function BlogSection() {
  return (
    <div>
     {/* Start Blog Section */}
<div className="blog-section">
  <div className="container">
    <div className="row mb-5">
      <div className="col-md-6">
        <h2 className="section-title">Blog Recent</h2>
      </div>
      <div className="col-md-6 text-start text-md-end">
        <Link to='/blog' className="more">Voir Plus</Link>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
        <div className="post-entry">
          <Link to='' className="post-thumbnail"><img src={img1} alt="Image" className="img-fluid" /></Link>
          <div className="post-content-entry">
            <h3><Link to=''>First Time Home Owner Ideas</Link></h3>
            <div className="meta">
              <span>by <Link to=''>Kristin Watson</Link></span> <span>on <Link to=''>Dec 19, 2021</Link></span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
        <div className="post-entry">
          <Link to='' className="post-thumbnail"><img src={img2} alt="Image" className="img-fluid" /></Link>
          <div className="post-content-entry">
            <h3><Link to=''>How To Keep Your Furniture Clean</Link></h3>
            <div className="meta">
              <span>by <Link to=''>Robert Fox</Link></span> <span>on <Link to=''>Dec 15, 2021</Link></span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
        <div className="post-entry">
          <Link to='' className="post-thumbnail"><img src={img3} alt="Image" className="img-fluid" /></Link>
          <div className="post-content-entry">
            <h3><Link to=''>Small Space Furniture Apartment Ideas</Link></h3>
            <div className="meta">
              <span>by <Link to=''>Kristin Watson</Link></span> <span>on <Link to=''>Dec 12, 2021</Link></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Blog Section */}

    </div>
  )
}
