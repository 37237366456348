import React from 'react'
import img from '../assets/images/Location engins/engin-3.jpg'
import img1 from '../assets/images/envelope-outline.svg'
import { FaFacebookF, FaInstagram, FaLinkedin, FaPaperPlane, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import logo from '../assets/images/petit_logo.jpg'

export default function Footer() {
  return (
    <div>
     {/* Start Footer Section */}
<footer className="footer-section">
  <div className="container relative">
    <div className="sofa-img">
      <img src={img} alt="Image" className="img-fluid" />
    </div>
    <div className="row">
      <div className="col-lg-8">
        <div className="subscription-form">
          <h3 className="d-flex align-items-center"><span className="me-1"><img src={img1} alt="Image" className="img-fluid" /></span><span>S'inscrire à la Newsletter</span></h3>
          <form action="#" className="row g-3">
            <div className="col-auto">
              <input type="text" className="form-control" placeholder="Entez votre nom" />
            </div>
            <div className="col-auto">
              <input type="email" className="form-control" placeholder="Entez votre email" />
            </div>
            <div className="col-auto">
              <button className="btn btn-primary">
                <span className="fa " /> <FaPaperPlane />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="row g-5 mb-5">
      <div className="col-lg-4">
        <div className="mb-4 footer-logo-wrap"><Link to='/' className="footer-logo"> <img src={logo} alt=''/> </Link></div>
        <p className="mb-4">La SFID (Société Forestière et Industrielle de la Doumé) est une société du groupe Rougier. Rougier est installé depuis 1969 au Cameroun à travers sa filiale SFID qui exploite aujourd'hui 550 000 ha de forêts pour une production de l'ordre de 200 000 m³ de grumes/an. La SFID emploie plus de 1 000 personnes.</p>
        <ul className="list-unstyled custom-social">
          <li><Link to='' ><span className="fa fa-brands"/> <FaFacebookF/> </Link></li>
          <li><Link to='' ><span className="fa fa-brands" /> <FaTwitter/> </Link></li>
          <li><Link to='' ><span className="fa fa-brands"/> <FaInstagram/> </Link></li>
          <li><Link to='' ><span className="fa fa-brands"/> <FaLinkedin/> </Link></li>
        </ul>
      </div>
      <div className="col-lg-8">
        <div className="row links-wrap">
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><Link to='/about' >A Propos</Link></li>
              <li><Link to='/service'>Nos Services</Link></li>
              {/* <li><Link to='/blog'>Blog</Link></li> */}
              <li><Link to='/contact'>Contactez-nous</Link></li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><Link to='/'>Soutien</Link></li>
              <li><Link to='/'>Base de Connaissances</Link></li>
              {/* <li><Link to='/'>Chat en Direct</Link></li> */}
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><Link to='/'>Jobs</Link></li>
              <li><Link to='/'>Notre équipe</Link></li>
              <li><Link to='/'>Direction</Link></li>
              <li><Link to='/'>Politique de Confidentiealité</Link></li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><Link to='/shop'>Bois Nordic</Link></li>
              <li><Link to='/shop'>Rondin de Bois</Link></li>
              <li><Link to='/shop'>Maison Rusrique en Bois</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="border-top copyright">
      <div className="row pt-4">
        <div className="col-lg-6">
          <p className="mb-2 text-center text-lg-start">Copyright ©. All Rights Reserved. — Designed with love by <a href="https://untree.co">DigiTech</a>
          </p>
        </div>
        <div className="col-lg-6 text-center text-lg-end">
          <ul className="list-unstyled d-inline-flex ms-auto">
            <li className="me-4"><Link to='/'>Terms &amp; Conditions</Link></li>
            <li><Link to=''>Politique de Confidentiealité</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
{/* End Footer Section */}

    </div>
  )
}
