import React from 'react'
import HeroService from '../components/HeroService'
import ProductSection from '../components/ProductSection'
import ChooseService from '../components/ChooseService'
import LocationEngin from '../components/LocationEngin'
import ConstructionMaison from '../components/ContructionMaison'

export default function Services() {
  return (
    <div>
      <HeroService/>
      <ProductSection/>
      <LocationEngin/>
      <ConstructionMaison/>
      <ChooseService/>
    </div>
  )
}
